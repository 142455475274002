import React from 'react'
import image from '../../assets/painting/after_2.jpg'
import'../InfoSection.css'
function InfoSection() {
  return (
    <div className='service-info-section-wrapper'>
        <div>
            <h1>Painting</h1>
            <p><img src={image} alt='painting' className='service-info-section-pic'/>At Smart Tech Experts LLC, we specialize in transforming homes and apartments with our professional painting services. Whether you’re looking to refresh a single room or renovate your entire property, our team of experienced painters delivers high-quality results with attention to detail and precision. We are committed to enhancing the beauty and value of your space through expert craftsmanship and a flawless finish.
            
            <p>Elevate the look and feel of your home’s interior with our expert painting services. We offer a comprehensive range of interior painting solutions, including walls, ceilings, trim, and cabinetry. Our team works with you to select the perfect colors and finishes that reflect your personal style and create a welcoming atmosphere. We ensure a meticulous preparation process, including patching and sanding, to deliver a smooth and long-lasting result.</p>
            </p>
            
        </div>
        
    </div>
  )
}

export default InfoSection