import React from 'react'
import WavyHero from '../../components/WavyHero'
import InfoSection from './InfoSection'
import ServiceGallery from '../../components/ServiceGallery'
import add1 from '../../assets/additional/shelf1_before.jpg'
import add2 from '../../assets/additional/shelf1_after.jpg'
import add3 from '../../assets/additional/shelf2_before.jpg'
import add4 from '../../assets/additional/shelf2_after.jpg'
import add5 from '../../assets/additional/backsplash.jpg'
import add6 from '../../assets/additional/bed_shelf.JPG'
import add7 from '../../assets/additional/bedroom1.JPG'
import add8 from '../../assets/additional/gazebo.jpg'
import add9 from '../../assets/additional/shuttersproj.jpg'
import add10 from '../../assets/additional/kitchen.jpg'
import add11 from '../../assets/additional/roofing1_before.jpg'
import add12 from '../../assets/additional/roofing1_after.jpg'






function Additional() {
    const pics=[
        {image:add1,overlay:"BEFORE",title:"CLOSET SHELVES - BEFORE"},
        {image:add2,overlay:"AFTER",title:"CLOSET SHELVES - AFTER"},
        {image:add3,overlay:"BEFORE",title:"LAUNDRY SHELVES - BEFORE"},
        {image:add4,overlay:"AFTER",title:"LAUNDRY SHELVES - AFTER"},
        {image:add5,title:"KITCHEN BACKSPLASH"},
        {image:add6,title:"FLOATING WALL SHELVES"},
        {image:add7, title:"BEDROOM AMENETIES INSTALLATION"},
        {image:add8, title:"PATIO GAZEBO"},
        {image:add9,title:"PATIO CHIMNEY, SHUTTERS, TV MOUNT, & PROJECTOR SCREEN"},
        {image:add10,title:"KITCHEN REMODEL"},
        {image:add11,overlay:"BEFORE",title:"ROOF REPAIR - BEFORE"},
        {image:add12,overlay:"AFTER",title:"ROOF REPAIR - AFTER"},]
    return (
        <div>
            <WavyHero pic={add8} title={"ADDITIONAL SERVICES"}/>
            <InfoSection/>
            <ServiceGallery pics={pics}/>

        </div>
    )
}

export default Additional