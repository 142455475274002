import React from 'react'
import WavyHero from '../../components/WavyHero'
import InfoSection from './InfoSection'
import ServiceGallery from '../../components/ServiceGallery'
import plumbing1 from '../../assets/plumbing/plumbing_1.jpg'
import plumbing2 from '../../assets/plumbing/plumbing_2.jpg'
import plumbing3 from '../../assets/plumbing/plumbing_3.JPG'
import plumbing4 from '../../assets/plumbing/plumbing_4.jpg'
import plumbing5 from '../../assets/plumbing/plumbing_5.jpg'
import plumbing6 from '../../assets/plumbing/plumbing_6.jpg'
import plumbing7 from '../../assets/plumbing/plumbing_6_after.jpg'


function Plumbing() {
    const pics=[
        {image:plumbing1, title:"SINK ROUGH-IN PLUMBING INSTALLATION"},
        {image:plumbing2, title:"TOILET ROUGH-IN PLUMBING INSTALLATION"},
        {image:plumbing3, title:"SEWER LINE REPAIR AND INSTALLATION"},
        {image:plumbing4,title:"WASHING MACHINE DRAIN LINE"},
        {image:plumbing6,overlay:"BEFORE",title:"Pool leak detection, repairs, replacement of pipes - BEFORE"},
        {image:plumbing7,overlay:"AFTER",title:"Pool leak detection, repairs, replacement of pipes - AFTER"},
        {image:plumbing5,title:"TANKLESS WATER HEATER INSTALLATION"}
    ]
    return (
        <div>
            <WavyHero pic={plumbing1} title={"PLUMBING"}/>
            <InfoSection/>
            <ServiceGallery pics={pics}/>

        </div>
    )
}

export default Plumbing