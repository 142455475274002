import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactTest = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_nb6mvxk', 'template_lx5arsa', form.current, {
        publicKey: 'Xqar6L5gWhwZrnJwB',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="from_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form>
    </>
  );
};
export default ContactTest;
