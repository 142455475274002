import './WavyHero.css'
function WavyHero({pic,title}) {
    return (
        <div className="wavy-hero-wrapper">
            <img src={pic} className='wavy-hero-image' alt={title}/>
            <div className='wavy-hero-title'>
                {title}
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='hero-waves-alt'><path fill="#0C5CA4" fill-opacity="1" d="M0,288L80,277.3C160,267,320,245,480,245.3C640,245,800,267,960,272C1120,277,1280,267,1360,261.3L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='hero-waves'><path fill="rgb(248, 248, 248)" fill-opacity="1" d="M0,288L80,277.3C160,267,320,245,480,245.3C640,245,800,267,960,272C1120,277,1280,267,1360,261.3L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>


        </div>
    )
}

export default WavyHero