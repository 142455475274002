import React from 'react'
import '../Home.css'
import logo from '../../logo.png'
function Hero() {
 
  return (
    <>
    <div className='hero-container'>
        <img src={logo} alt='stain and seal work' className='hero-logo'/>
        <p>CRAFTING YOUR VISION, PERFECTING YOUR LIVING SPACE.</p>
                <button><a href='tel:469-728-4201' rel='noreferrer'>CALL NOW</a></button> 
    </div>

    </>
  )
}

export default Hero