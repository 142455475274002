import React, { useEffect, useState } from 'react'
import './Navbar.css'
import logo from '../assets/logo.png'
import { Link } from 'react-router-dom'

function Navbar() {
    const[showSidebar,setShowSidebar]=useState(false);
    const handleSidebar=()=>{
        
        setShowSidebar(!showSidebar);
    }

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth > 1199 && showSidebar) {
            setShowSidebar(false);
          }
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [showSidebar]);


    useEffect(()=>{
        if(showSidebar && window.innerWidth <= 1199){
            document.body.style.overflow="hidden"; //fix this, every other click on the nav page element activates this even though the screen is big
        }
        else{
            document.body.style.overflow="initial";
        }
    },[showSidebar])

    const[showDropdown,setShowDropdown]=useState(false);
    const handleDropdown=()=>{
        setShowDropdown(!showDropdown);
    }

    return (
        <nav>
            <div className={showSidebar?'blacked-out-cover':'hide-blacked-out-cover'} onClick={handleSidebar}/>
            <Link to={'/'} className='nav-logo-wrapper'>
                <img className='nav-logo' src={logo} alt='logo'/>
            </Link>
            <div className={showSidebar?"hamburger x":'hamburger'} onClick={handleSidebar}>
                <div className='hamburger-line-1'/>
                <div className='hamburger-line-2'/>
                <div className='hamburger-line-3'/>
            </div>
            <div className={showSidebar?'nav-items-wrapper sidebar':'nav-items-wrapper'}>

                <div className={showSidebar?'nav-sidebar-content-wrapper':"nav-items-wrapper"}>
                    <Link to={'/'} onClick={handleSidebar} className='nav-item'>HOME</Link>


                    <div className='dropdown-item' onClick={handleDropdown}>
                        <div className='dropdown-title'>
                            SERVICES
                            <div className={showDropdown?'drop-indicator minus':'drop-indicator'}>
                                <div className='drop-line-1'/>
                                <div className='drop-line-2'/>
                            </div>
                        </div>
                        <div className={showDropdown? 'dropdown-content sidebar':'dropdown-content'}>
                            <Link to={'/services'} onClick={handleSidebar} className='dropdown-content-item'>All Services</Link>

                            <Link to={'/services/bathrooms'} onClick={handleSidebar} className='dropdown-content-item'>Bathroom Remodeling</Link>

                            <Link to={'/services/painting'} onClick={handleSidebar} className='dropdown-content-item'>Painting</Link>

                            <Link to={'/services/flooring'} onClick={handleSidebar} className='dropdown-content-item'>Flooring</Link>

                            <Link to={'/services/electrical'} onClick={handleSidebar} className='dropdown-content-item'>Electrical</Link>

                            <Link to={'/services/plumbing'} onClick={handleSidebar}className='dropdown-content-item'>Plumbing</Link>

                            <Link to={'/services/additional'} onClick={handleSidebar} className='dropdown-content-item'>More</Link>

                        </div>
                    </div>
                    <Link to={'/contact'} onClick={handleSidebar} className='nav-item'>CONTACT US</Link>

                </div>
            </div>





        </nav>
    )
}

export default Navbar