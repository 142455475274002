import React from 'react'
import image from '../../assets/electrical/panel_1.jpg'
import'../InfoSection.css'
function InfoSection() {
  return (
    <div className='service-info-section-wrapper'>
        <div>
            <h1>Electrical</h1>
            <p><img src={image} alt='electric' className='service-info-section-pic'/>At Smart Tech Experts LLC, we provide reliable and professional electrical services to ensure the safety and efficiency of your home’s electrical systems. Our team of certified electricians is dedicated to delivering top-notch solutions, whether you need routine maintenance, repairs, or a complete electrical overhaul. We prioritize quality and safety in every project, making your home a secure and well-functioning space.
            
            <p>Whether you're remodeling an existing space or building new, our comprehensive electrical services cover all aspects of installations and upgrades. From wiring and panel upgrades to installing lighting fixtures and smart home systems, we ensure that your electrical infrastructure is up-to-date and capable of supporting your lifestyle needs. Our expert team works with precision to provide installations that are safe, efficient, and tailored to your specific requirements.</p>
            </p>
            
        </div>
        
    </div>
  )
}

export default InfoSection