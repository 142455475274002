import React from 'react'
import WavyHero from '../../components/WavyHero'
import InfoSection from './InfoSection'
import ServiceGallery from '../../components/ServiceGallery'
import flooring1 from '../../assets/flooring/floor.jpg'




function Flooring() {
    const pics=[{image:flooring1}]
    return (
        <div>
            <WavyHero pic={flooring1} title={"FLOORING"}/>
            <InfoSection/>
            <ServiceGallery pics={pics}/>

        </div>
    )
}

export default Flooring