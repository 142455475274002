import React from 'react'
import WavyHero from '../../components/WavyHero'
import InfoSection from './InfoSection'
import ServiceGallery from '../../components/ServiceGallery'
import electric1 from '../../assets/electrical/lighting_1.jpg'
import electric2 from '../../assets/electrical/lighting_2.JPG'
import electric3 from '../../assets/electrical/lighting_3.JPG'
import electric4 from '../../assets/electrical/lighting_4.JPG'



function Electrical() {
    const pics=[{image:electric1,title:"Exterior LED Lighting"},{image:electric2,title:"Vanity Lighting"},{image:electric3,title:"Vanity Lighting",position:"top"},{image:electric4,title:"Vanity Lighting",position:"top"}]
    return (
        <div>
            <WavyHero pic={electric1} title={"ELECTRICAL"}/>
            <InfoSection/>
            <ServiceGallery pics={pics}/>

        </div>
    )
}

export default Electrical