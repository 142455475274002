import React from 'react'
import'../Home.css'
import additional from '../../assets/additional/additional.jpg'
import bathroom from '../../assets/showers/shower4.JPG'
import flooring from '../../assets/flooring/floor_1.JPG'
import electrical from '../../assets/electrical/lighting_1.jpg'
import painting from '../../assets/painting/after_1.jpg'
import plumbing from '../../assets/plumbing/plumbing_1.jpg'
import Card from '../../components/Card'
import { Link } from 'react-router-dom'
function Services() {
    return (
        <div className='services-section'>
            <h1 className='services-title'>WHAT SERVICES DO WE OFFER?</h1>
            <p className='services-description'>At Smart Tech Experts LLC, we offer a comprehensive range of services to meet all your home and apartment renovation needs. Our expertise includes but is not limited to flooring, painting, electrical work, plumbing, and bathroom remodeling. We pride ourselves on delivering high-quality craftsmanship and exceptional results in every project we undertake. Whether you're looking to revamp your entire home or just need specific repairs, our experienced team is dedicated to ensuring your vision comes to life with professionalism and attention to detail.</p>
            <h3 className='our-services-title'>OUR SERVICES</h3>
            <div className='services-grid'>

                <Link to={'/services/bathrooms'}><Card image={bathroom} title={"BATHROOMS"} desc={"Transform your bathroom into a sanctuary. Our expert team specializes in creating stylish, functional, and luxurious bathrooms tailored to your vision. From fixtures to finishes, we ensure every detail is perfect."}/></Link>

                <Link to={'/services/painting'}><Card image={painting} title={"PAINTING"} desc={"Refresh your space with a fresh coat of paint. Our professional painters bring life to your walls and exterior, offering precision and quality that stands out. Whether it's an accent wall or a whole-home palette, we have you covered."}/></Link>


                <Link to={'/services/electrical'}><Card image={electrical} title={"ELECTRICAL"} desc={"Ensure your home is safe and well-lit with our expert electrical services. We handle everything from rewiring to new installations, providing reliable solutions that power your home efficiently and safely."}/></Link>
                
                <Link to={'/services/plumbing'}><Card image={plumbing} title={"PLUMBING"} desc={"Keep your plumbing in top condition with our skilled services. Whether it's repairs, installations, or upgrades, we ensure your plumbing system runs smoothly and efficiently, offering peace of mind and convenience."}/></Link>

                <Link to={'/services/flooring'}><Card image={flooring} title={"FLOORING"} desc={"Upgrade your floors for beauty and durability. We offer a wide range of flooring options, from hardwood to tile, ensuring a perfect fit for your style and needs. Experience craftsmanship that enhances every step you take."}/></Link>

                <Link to={'/services/additional'}><Card image={additional} title={"ADDITIONAL SERVICES"} desc={"Need something more? From custom renovations to minor repairs, our additional services cover a wide range of home improvement needs. Trust us to deliver exceptional results, no matter the project size."}/></Link>

    

            </div>
        </div>
    )
}

export default Services