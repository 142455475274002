import React from 'react'
import '../Services.css'
import heroImg from '../../assets/showers/shower1.jpg'
function Hero() {
    return (
        <>
        <div className='services-hero'>
            <img src={heroImg} alt={"bathroom"} className='services-hero-img'/>
            <div className='services-hero-content'>
                <h1>SERVICES</h1>
                <p>Delivering top-notch home renovation services with expertise in flooring, painting, electrical, plumbing, and bathroom remodeling</p>
            </div>
        </div>
        <div className='about-services-wrapper'>
            <div>
                <h1>How Can We Transform Your Space?</h1>
                <p>At Smart Tech Experts LLC, we offer a comprehensive range of services to meet all your home and apartment renovation needs. Our expertise includes but is not limited to flooring, painting, electrical work, plumbing, and bathroom remodeling. We pride ourselves on delivering high-quality craftsmanship and exceptional results in every project we undertake. Whether you're looking to revamp your entire home or just need specific repairs, our experienced team is dedicated to ensuring your vision comes to life with professionalism and attention to detail.</p>
            </div>
        </div>
        </>
    )
}

export default Hero