import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools,faPencilAlt,faCheckCircle,faShieldAlt } from '@fortawesome/free-solid-svg-icons'
import { Parallax } from 'react-parallax'
// import parallaxImage from '../../assets/electrical/lighting_1.jpg'
import parallaxImage from '../../assets/flooring/floor_1.JPG'



function About() {
    return (
        <div className='about-container'>
            {/* <div className='about-banner'>

                <p>CRAFTING YOUR VISION, PERFECTING YOUR LIVING SPACE.</p>
                <button><a href='tel:469-728-4201' rel='noreferrer'>CALL NOW</a></button> 
                
            </div> */}
            <div className='about-content'>
                <div className='about-general'>
                    <h1 className='about-title'>WHAT SETS US APART?</h1>
                    <p className='about-description'>At Smart Tech Experts LLC, we pride ourselves on delivering exceptional home and apartment renovations and repairs in the Dallas area. Our commitment to quality craftsmanship and attention to detail sets us apart as the premier choice for transforming living spaces. </p>
                </div>
                <div className='advantages-section'>
                    <div className='advantage'>
                        <div className='advantage-icon-container'>
                            <FontAwesomeIcon className='advantage-icon' icon={faTools} color='white' size='4x'/>

                        </div>
                        <h1>Expert Craftsmanship</h1>
                        <p>Our seasoned professionals bring years of experience to every project, ensuring unparalleled quality and precision in every detail.</p>
                    </div>
                    <div className='advantage'>
                        <div className='advantage-icon-container' >
                            <FontAwesomeIcon className='advantage-icon' icon={faPencilAlt} color='white' size='4x'/>

                        </div>
                        <h1>Tailored Solutions</h1>
                            <p>We understand that every home is unique, which is why we offer personalized renovation plans crafted to match your vision and lifestyle perfectly.</p>


                        
                    </div>
                    <div className='advantage'>
                        <div className='advantage-icon-container'>
                            <FontAwesomeIcon className='advantage-icon' icon={faCheckCircle} color='white' size='4x'/>
                        </div>
                        <h1>Smooth Operations</h1>
                        <p>From start to finish, we handle every aspect of your renovation with efficiency and transparency, making the process smooth and stress-free for you.</p>

                    </div>
                    <div className='advantage'>
                        <div className='advantage-icon-container'>
                            <FontAwesomeIcon className='advantage-icon' icon={faShieldAlt} color='white' size='4x'/>
                        </div>
                        <h1>Trusted Reputation</h1>
                        <p>With a solid track record of satisfied clients in the Dallas community, you can trust Smart Tech Experts LLC to deliver exceptional results, every time.</p>
                        
                    </div>

                </div>
            </div>
            <Parallax strength={300} bgImage={parallaxImage} blur={{ min: 2, max: 4 }}>
                <div className='about-banner dark'>
                    <p>Ready to Transform Your Home or Apartment Complex?</p>
                    <button><a href='tel:469-728-4201' rel='noreferrer'>Call (469)728-4201</a></button>
                    <p>For Quality Renovations!</p>    
                </div>
            </Parallax>
            
        </div>
    )
}

export default About