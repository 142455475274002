import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';
import logo from '../../assets/logo.png';


function Contact() {
    const form = useRef();
    const [status,setStatus]=useState(false);
    const [statusMsg, setStatusMsg]=useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_nb6mvxk', 'template_lx5arsa', form.current, {
            publicKey: 'Xqar6L5gWhwZrnJwB',
        })
        .then(
            () => {
            console.log('SUCCESS!');
            setStatus(true);
            setStatusMsg("Your Submission Was A SUCCESS!")
            },
            (error) => {
            console.log('FAILED...', error.text);
            setStatusMsg("Something Went Wrong, Try Again Later!")
            },
        );

    };

    return (
        <div className='contact-container'>
            <div className='form-header-img'>
                <img src={logo} alt='company logo' />
            </div>
            <div className='form-description'>
                <h1>Receive A Free Quote!</h1>
                <p>
                    Do you have a question about our services? We are more than happy to answer any questions that you may have!
                    <br/><br/>
                    You can also receive a free quote when you fill out and submit the form below! Please thoroughly fill out this form to receive an accurate quote or answer to a question that you might have! Leave a detailed message with what you're looking for and the size of the area that you're looking to remodel, repair, or create!
                    <br/><br/>
                    You can also receive an estimate by calling our company phone number or email:<br/><span style={{ color: "var(--primary)" }}>(469) 728-4201</span>
                    <br/><span style={{ color: "var(--primary)" }}>pablotzintzun10@gmail.com</span>
                </p>
            </div>
            <form ref={form} onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contact v1" />
                <div>
                    <span style={{ color: "red" }}>*</span><input id='name' type='text' name="full_name" placeholder='Full Name' required />
                </div>
                <div>
                    <span style={{ color: "red" }}>*</span><input id='phone' type='tel' name='phone_number' placeholder='Phone Number' required />
                </div>
                <div>
                    <span style={{ color: "red" }}>*</span><input id='email' name='email' type='email' placeholder='Email address' required />
                </div>
                <div className='form-check-boxes'>
                    <br/>
                    <label>I'm interested in the following:</label>
                    <br/>
                    <div>
                        <input type='checkbox' name='Bathroom_Remodeling' value="Bathroom Remodeling" id='bathroom-check' />
                        <label htmlFor='bathroom-check'>Bathroom Remodeling</label>
                    </div>
                    <div>
                        <input type='checkbox' value="Electrical Work" name='Electrical' id='electrical-check' />
                        <label htmlFor='electrical-check'>Electrical Work</label>
                    </div>
                    <div>
                        <input type='checkbox' value="Flooring" name='Flooring' id='flooring-check' />
                        <label htmlFor='flooring-check'>Flooring</label>
                    </div>
                    <div>
                        <input type='checkbox' value="Painting" name='Painting' id='painting-check' />
                        <label htmlFor='painting-check'>Painting</label>
                    </div>
                    <div>
                        <input type='checkbox' value="Plumbing" name='Plumbing' id='plumbing-check' />
                        <label htmlFor='plumbing-check'>Plumbing</label>
                    </div>
                    <div>
                        <input type='checkbox' value="Other" name='Other' id='other-check' />
                        <label htmlFor='other-check'>Other (Please Specify Down Below)</label>
                    </div>
                </div>
                <div className='message-area'>
                    <label htmlFor='message'>Message<span style={{ color: "red" }}>*</span></label>
                    <textarea id='message' name='message' rows='5' placeholder='Write Something...'></textarea>
                </div>
                <br/>
                <div>
                    <input type='checkbox' value="User Agreed To Terms" name='agreed' id='agreement' required />
                    <label htmlFor='agreement'>
                        Please agree to the terms in order to submit your request<span style={{ color: "red" }}>*</span>
                    </label>
                    <p>
                        By selecting this box, I confirm that this is my personal mobile phone number and give permission to Smart Tech Experts LLC to contact me through phone calls and text messages (SMS) at this number. I understand that I can withdraw my consent at any time by contacting Smart Tech Experts LLC. Standard message and data rates may apply.
                    </p>
                </div>
                {!status&&<button type='submit' value="Send" className='form-btn'>Submit</button>}
                {statusMsg&&status?<p style={{color:"green", fontWeight:"bold",fontSize:'24px'}}>{statusMsg}</p>:<p style={{color:"red", fontWeight:"bold",fontSize:'24px'}}>{statusMsg}</p>}
            </form>
        </div>
    );
}

export default Contact;
