import React from 'react'
import Hero from './Services/Hero'
import Service from './Services/Service'
import bathroom from '../assets/showers/shower2.jpg'
import painting from '../assets/painting/after_2.jpg'
import electrical from '../assets/electrical/lighting_1.jpg'
import flooring from '../assets/flooring/floor.jpg'
import plumbing from '../assets/plumbing/plumbing_1.jpg'
import additional from '../assets/additional/additional.jpg'
function Services() {
    return (
        <div className='services-page-wrapper'>
            <Hero/>
            <Service pic={bathroom} name="BATHROOM REMODELING" about="Transform your bathroom into a sanctuary. Our expert team specializes in creating stylish, functional, and luxurious bathrooms tailored to your vision. From fixtures to finishes, we ensure every detail is perfect." link="/services/bathrooms"/>

            <Service pic={painting} name="PAINTING" about="Transform your bathroom into a sanctuary. Our expert team specializes in creating stylish, functional, and luxurious bathrooms tailored to your vision. From fixtures to finishes, we ensure every detail is perfect." link="/services/painting"/>

            <Service pic={electrical} name="ELECTRICAL" about="Ensure your home is safe and well-lit with our expert electrical services. We handle everything from rewiring to new installations, providing reliable solutions that power your home efficiently and safely." link="/services/electrical"/>

            <Service pic={flooring} name="FLOORING" about="Upgrade your floors for beauty and durability. We offer a wide range of flooring options, from hardwood to tile, ensuring a perfect fit for your style and needs. Experience craftsmanship that enhances every step you take." link="/services/flooring"/>

            <Service pic={plumbing} name="PLUMBING" about="Keep your plumbing in top condition with our skilled services. Whether it's repairs, installations, or upgrades, we ensure your plumbing system runs smoothly and efficiently, offering peace of mind and convenience." link="/services/plumbing"/>

            <Service pic={additional} name="ADDITIONAL SERVICES" about="Need something more? From custom renovations to minor repairs, our additional services cover a wide range of home improvement needs. Trust us to deliver exceptional results, no matter the project size." link="/services/additional"/>

        </div>
    )
}

export default Services