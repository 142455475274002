import React from 'react'
import image from '../../assets/additional/shelf1_after.jpg'
import'../InfoSection.css'
function InfoSection() {
  return (
    <div className='service-info-section-wrapper'>
        <div>
            <h1>Additional Services</h1>
            <p><img src={image} alt='additional' className='service-info-section-pic'/>At Smart Tech Experts LLC, we go beyond traditional remodeling and repair services to offer a range of additional options tailored to enhance your home. From custom shelf making for optimized storage solutions to the installation of patio gazebos for outdoor comfort, we specialize in transforming your living spaces to meet your unique needs. Our services also include expert TV mounting to elevate your entertainment experience and professional shutter installation for enhanced aesthetics and functionality. Whether you’re looking to improve organization, expand outdoor living areas, or upgrade your home’s technology and décor, our skilled team is dedicated to delivering quality craftsmanship and exceptional results.
            
            <p>With a commitment to excellence and customer satisfaction, Smart Tech Experts LLC provides comprehensive home improvement solutions that exceed expectations. Whether it’s customizing storage options with tailored shelves, creating inviting outdoor spaces with stylish gazebos, or enhancing your entertainment setup with precision TV mounting, we ensure each project is executed with professionalism and attention to detail. Trust us to enhance your home’s functionality, beauty, and value with our diverse range of specialized services.</p>
            </p>
            
        </div>
        
    </div>
  )
}

export default InfoSection