import React from 'react'
import image from '../../assets/flooring/floor_1.JPG'
import'../InfoSection.css'
function InfoSection() {
  return (
    <div className='service-info-section-wrapper'>
        <div>
            <h1>Flooring</h1>
            <p><img src={image} alt='electric' className='service-info-section-pic'/>At Smart Tech Experts LLC, we specialize in transforming homes and apartments with high-quality flooring solutions that combine aesthetics, durability, and functionality. Whether you’re looking to upgrade a single room or redo the entire home, our expert team is dedicated to providing exceptional craftsmanship and attention to detail, creating beautiful and lasting floors that enhance the value and appeal of your space.
            
            <p>Our comprehensive flooring services cover everything from installation to replacement, ensuring that every step is handled with care and precision. We work with a variety of flooring materials, including hardwood, laminate, tile, vinyl, and carpet, to provide you with the perfect options that suit your style and needs. Our experienced professionals ensure that your new floors are installed seamlessly, delivering a flawless finish that you can enjoy for years to come.</p>
            </p>
            
        </div>
        
    </div>
  )
}

export default InfoSection