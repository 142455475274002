import React from 'react'
import image from '../../assets/showers/shower8.JPG'
import'../InfoSection.css'
function InfoSection() {
  return (
    <div className='service-info-section-wrapper'>
        <div>
            <h1>Bathroom Remodeling</h1>
            <p><img src={image} alt={"bethroom1"} className='service-info-section-pic'/>Elevate your restroom with Smart Tech Experts LLC, where we specialize in transforming these essential spaces into modern sanctuaries of comfort and style. Whether you’re looking for a complete makeover or subtle upgrades, we bring your vision to life with exceptional craftsmanship and attention to detail, ensuring your restroom is both beautiful and functional.
            
            <p>Our team collaborates with you to create a restroom design that reflects your personal taste and lifestyle. We offer a wide range of high-quality fixtures and finishes, allowing you to choose the perfect elements that enhance your space. From contemporary vanities to sleek, energy-efficient lighting, every detail is carefully selected to provide a customized, elegant look.</p>
            </p>
            
        </div>
        
    </div>
  )
}

export default InfoSection