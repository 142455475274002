import React from 'react'
import WavyHero from '../../components/WavyHero'
import InfoSection from './InfoSection'
import ServiceGallery from '../../components/ServiceGallery'
import painting1 from '../../assets/painting/before_1.jpg'
import painting2 from '../../assets/painting/after_1.jpg'
import painting3 from '../../assets/painting/before_2.jpg'
import painting4 from '../../assets/painting/after_2.jpg'



function Painting() {
    const pics=[{image:painting1,overlay:"BEFORE", title:"EXTERIOR PAINTING - BEFORE"},{image:painting2,overlay:"AFTER", title:"EXTERIOR PAINTING - AFTER"},{image:painting3,overlay:"BEFORE",title:"STAIN & SEAL - BEFORE"},{image:painting4,overlay:"AFTER", title:"STAIN & SEAL - AFTER"}]
    return (
        <div>
            <WavyHero pic={painting4} title={"PAINTING"}/>
            <InfoSection/>
            <ServiceGallery pics={pics}/>

        </div>
    )
}

export default Painting