import React from 'react'
import Hero from './Home/Hero'
import About from './Home/About'
import Services from './Home/Services'
function Home() {
    return (
        <>
        <div className='home'>
            <Hero/>
            <About/>
            <Services/>
        </div>
        </>
    )
}

export default Home