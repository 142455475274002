import React from 'react'
import image from '../../assets/plumbing/plumbing_3.JPG'
import'../InfoSection.css'
function InfoSection() {
  return (
    <div className='service-info-section-wrapper'>
        <div>
            <h1>Plumbing</h1>
            <p><img src={image} alt='electric' className='service-info-section-pic'/>We specialize in comprehensive plumbing installations and upgrades, providing top-notch solutions for new constructions, remodels, and system improvements. From installing new pipes, fixtures, and water heaters to upgrading outdated plumbing systems, we ensure that your home’s plumbing is modern, efficient, and built to last. Our expertise in various plumbing technologies ensures that you receive the best solutions tailored to your needs.
            
            <p>Plumbing issues can disrupt your daily routine and cause significant damage if not addressed promptly. Our team at Smart Tech Experts LLC offers fast and reliable repair services to tackle everything from leaks and clogs to burst pipes and water heater malfunctions. We use advanced diagnostic tools to quickly identify the problem and provide effective, long-lasting repairs, restoring your plumbing system to optimal functionality.</p>
            </p>
            
        </div>
        
    </div>
  )
}

export default InfoSection