import React from 'react'
import'./Card.css'
function Card({image,title,desc}) {
    return (
        <div className='card'>
            <div className='card-img-wrapper'>
                <img src={image} alt={title} className='card-img'/>
                <div className='card-img-text'>{title}</div>
            </div>

            <div className='card-text'>
                <div className='card-text-title'>{title}</div>
                <div className='card-text-desc'>{desc}</div>
            </div>
            
        </div>
    )
}

export default Card