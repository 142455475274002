import { Link } from "react-router-dom"


function Service({pic,name,about,link}) {
  return (
    <div className="service-main-wrapper">
        <div className='service-wrapper'>
            <img src={pic} alt={name} className="service-component-img"/>
            <div className="service-content-wrapper">
                <h1>{name}</h1>
                <p>{about}</p>
                <div className="more-btn-container">
                  <Link to={link}><button>LEARN MORE</button></Link></div>
            </div>
        </div>
    </div>
  )
}

export default Service