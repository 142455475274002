import { Link } from 'react-router-dom'
import'./Footer.css'

function Footer() {
    return (
        <div className="footer-container">
            <div className='footer-top-wrapper'>
                <div className='footer-inner-flex'>
                    <h1><Link to={'/services'}>SERVICES</Link></h1>
                    <Link to={'/services/bathrooms'}>BATHROOMS</Link>
                    <Link to={'/services/painting'}>PAINTING</Link>
                    <Link to={'/services/flooring'}>FLOORING</Link>
                    <Link to={'/services/electrical'}>ELECTRICAL</Link>
                    <Link to={'/services/plumbing'}>PLUMBING</Link>
                    <Link to={'/services/additional'}>ADDITIONAL SERVICES</Link>
                </div>

                <div className='footer-inner-flex'>
                    <h1><Link to={'/contact'}>CONTACT</Link></h1>
                    <p>Call (469) 728-4201</p>
                    <p>pablotzintzun10@gmail.com</p>

                </div>
            </div>
            <div className='footer-bottom-wrapper'>
                <span>Smart Tech Experts LLC</span> &copy; All Rights Reserved 2024
            </div>

        </div>
    )
}

export default Footer