import React from 'react'
import WavyHero from '../../components/WavyHero'
import bathroom from '../../assets/showers/shower3.jpg'
import InfoSection from './InfoSection'
import ServiceGallery from '../../components/ServiceGallery'
import bath1 from '../../assets/showers/shower1.jpg'
import bath2 from '../../assets/showers/shower2.jpg'
import bath3 from '../../assets/showers/shower3.jpg'
import bath4 from '../../assets/showers/shower4.JPG'
import bath5 from '../../assets/showers/shower5.JPG'
import bath6 from '../../assets/showers/shower6.JPG'
import bath7 from '../../assets/showers/shower7.JPG'
import bath8 from '../../assets/showers/shower8.JPG'
import bath9 from '../../assets/showers/shower9.JPG'
import bath10 from '../../assets/showers/shower10.JPG'
import bath11 from '../../assets/showers/shower11.JPG'

function Bathrooms() {
    const pics=[{image:bath1,title:"MODERN BATHROOM REMODEL"},{image:bath2,title:"MODERN BATHROOM REMODEL"},{image:bath3,title:"COUNTRY MIRROR FRAME",position:"top"},{image:bath4,title:"SHOWER REMODEL"},{image:bath5,title:"BATH TILE & GRANITE INSTALLATION"},{image:bath6, title:"SHOWER REMODEL"},{image:bath7, title:"BATH INSTALLATION & TILE WALL"},{image:bath8,title:"COMPLETE BATHROOM REMODEL"},{image:bath9, title:"GRANITE SHOWER SEAT"},{image:bath10,title:"SHOWER WALL & BACKSPLASH"},{image:bath11,title:"SHOWER REMODEL"}]
    return (
        <div>
            <WavyHero pic={bathroom} title={"BATHROOMS"}/>
            <InfoSection/>
            <ServiceGallery pics={pics}/>

        </div>
    )
}

export default Bathrooms