import React, { useState } from 'react'
import'./ServiceGallery.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
function ServiceGallery({pics}) {
  const [fsImg, setFsImg]=useState(null)
  const fsHandler = (image)=>{
    setFsImg(image)
  }

  return (
    <>
    <div className='service-gallery-container'>
        <h1 className='service-gallery-title'>OUR WORK SHOWS</h1>
    </div>
    <div className='service-gallery-container'>
        <div className='service-gallery-grid'>
        {pics.map((pic,i)=>(
          <div className='service-image-grid-item' key={i}>
            <div className='service-grid-image-container'>
              <img src={pic.image} alt={`service ${i}`} onClick={()=>fsHandler(pic.image)} className={pic.position?"top":""}/>
            </div>
            {pic.overlay&&<p>{pic.overlay}</p>}
            {pic.title&&<div className='service-grid-title'>{pic.title.toUpperCase()}</div>}
          </div>
        ))}
        </div>
    </div>
    {fsImg&&
      <div className='full-screen-img-container'>
        <div className='fs-x'><FontAwesomeIcon icon={faX} onClick={()=>setFsImg(null)} className='fs-x-icon' size={"2x"} color='white'/></div>
        <img src={fsImg} alt={"FullScreen"}/>
      </div>
    }


    </>
  )
}

export default ServiceGallery