import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact/Contact';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Bathrooms from './pages/Bathrooms/Bathrooms';
import Electrical from './pages/Electrical/Electrical';
import Flooring from './pages/Flooring/Flooring';
import Plumbing from './pages/Plumbing/Plumbing';
import Painting from './pages/Painting/Painting';
import Additional from './pages/Additional/Additional';
import ContactTest from './pages/Contact/ContactTest';

function App() {
  return (
    <div className="App">

      <Router>
      <Navbar/>
        <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/services/electrical' element={<Electrical/>}/>
          <Route path='/services/bathrooms' element={<Bathrooms/>}/>
          <Route path='/services/flooring' element={<Flooring/>}/>
          <Route path='/services/plumbing' element={<Plumbing/>}/>
          <Route path='/services/painting' element={<Painting/>}/>
          <Route path='/services/additional' element={<Additional/>}/>

          <Route path='/contact' element={<Contact/>}/>
          <Route path='/test' element={<ContactTest/>}/>
        </Routes>
        <Footer/>
      </Router>


    </div>
  );
}

export default App;
